<template>

  <main ref="resumen" id="resumen">
    <div class="py-2"><motivoConsultaShow :idHistoria="idHistoria"></motivoConsultaShow></div>
    <div class="py-2"><examenFisicoShow :idHistoria="idHistoria"></examenFisicoShow></div>
    <div class="py-2"><exploracionFisicaShow :idHistoria="idHistoria"></exploracionFisicaShow></div>
    <div class="py-2"><ordenClinicaShow :idHistoria="idHistoria"></ordenClinicaShow></div>
    <div class="py-2"><formulaMedicaShow :idHistoria="idHistoria"></formulaMedicaShow></div>
    <div class="py-2"><impresionDxShow :idHistoria="idHistoria"></impresionDxShow></div>
    <div class="py-2"><tipoPatologiaShow :idHistoria="idHistoria"></tipoPatologiaShow></div>
    <div class="py-2"><revisionSistemasShow :idHistoria="idHistoria"></revisionSistemasShow></div>
    <div>
      <div class="card-header bg-light "><span><i class="fa fa-syringe"> </i> Riesgo Cardiovascular</span> </div>
      <div class="card-body">
        <div class="py-2"><riesgosNoModificableShow :idHistoria="idHistoria"></riesgosNoModificableShow></div>
        <div class="py-2"><riesgosModificableShow :idHistoria="idHistoria"></riesgosModificableShow></div>
      </div>
    </div>
    <div class="py-2"><complicacionesIdentificadasShow :idHistoria="idHistoria"></complicacionesIdentificadasShow></div>
    <div class="py-2"><examenPiesShow :idHistoria="idHistoria"></examenPiesShow></div>
    <div class="py-2"><fragmingamShow :idHistoria="idHistoria" ></fragmingamShow></div>
    <div class="py-2"><finnisRiskShow :idHistoria="idHistoria" ></finnisRiskShow></div>
    <div class="py-2"><tFGShow :idHistoria="idHistoria" ></tFGShow></div>

   
  </main>

</template>
<script>
import motivoConsultaShow from "../../base/motivoConsulta/motivoConsultaShow";
import examenFisicoShow from "../../base/examenFisico/examenFisicoShow";
import ordenClinicaShow from "../../base/orden/ordenClinicaShow";
import formulaMedicaShow from "../../base/formulaMedica/formulaMedicaShow";
import impresionDxShow from "../../base/impresionDX/impresionDxShow";
import exploracionFisicaShow from "../../base/examenFisico/exploracionFisicaShow";
import tipoPatologiaShow from "../../base/baseTipoPatologia/tipoPatologiaShow";
import revisionSistemasShow from "../../base/baseUrgencia/revisionShow";
import riesgosModificableShow from "../../base/baseRiesgoCardiovascular/riesgosModificableShow";
import riesgosNoModificableShow from "../../base/baseRiesgoCardiovascular/riesgosNoModificableShow";
import complicacionesIdentificadasShow from "../../base/baseComplicacionesIdentificadas/complicacionesIdentificadasShow";
import examenPiesShow from "../../base/baseExamenPies/examenPiesShow";
import fragmingamShow from "../../base/baseFragmingam/fragmingamShow";
import finnisRiskShow from "../../base/baseFinnisRisk/finnisRiskShow";
import tFGShow from "../../base/baseFiltracionGlomerula/show";

export default {
  props: ['idHistoria','idUsuario', 'usuario'],
  components:{
    motivoConsultaShow,
    examenFisicoShow,
    ordenClinicaShow,
    formulaMedicaShow,
    impresionDxShow,
    exploracionFisicaShow,
    tipoPatologiaShow,revisionSistemasShow,
    riesgosModificableShow,
    riesgosNoModificableShow,
    complicacionesIdentificadasShow,examenPiesShow,
    fragmingamShow,finnisRiskShow,tFGShow
  },
}
</script>
<style scoped>
.resumen-container {
  height:300px;
  overflow-y:scroll
}

</style>